import { SxStyleProp } from 'rebass'

type StyleTypes = { [x: string]: SxStyleProp }

export const styles: StyleTypes = {
  bottomBanner: {
    width: '100%',
    py: 20,
    px: 5,
    position: 'absolute',
    bottom: '0',
    background: 'rgba(255,255,255,0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  }
}
export default styles
