import React, { useContext, useState } from 'react'
import SideDrawer from '../SideDrawer'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'

import { useTheme } from 'emotion-theming'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, createSvgIcon } from '@mui/material'
import { Flex, Text, Box, Button } from 'rebass'
import { styles } from './styles'
import { useTranslation } from 'react-i18next'
import config from 'digital-human/src/config'

const outlineStar =
  'm 22 9.24 l -7.19 -0.62 L 12 2 L 9.19 8.63 L 2 9.24 l 5.46 4.73 L 5.82 21 L 12 17.27 L 18.18 21 l -1.63 -7.03 L 22 9.24 z M 12 16.2 l -4.8 3 l 1.28 -5.56 l -4.48 -3.78 l 5.79 -0.3 L 12 3.82 l 2 5.62 l 6.02 0.43 l -4.44 3.922 l 1.461 5.565 L 12 16.2 z'
const insideStar =
  'M 12 16.2 l -4.8 3 l 1.28 -5.56 l -4.48 -3.78 l 5.79 -0.3 L 12 3.82 l 2 5.62 l 6.02 0.43 l -4.44 3.922 l 1.461 5.565 L 12 16.2 z'
interface FeedbackDrawerProps {
  setFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

interface StarsProps {
  feedbackRating: number
  index: number
  setFeedbackRating: React.Dispatch<React.SetStateAction<number[]>>
}

interface ThanksProps {
  setFeedbackSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

export const Feedback: React.FC = () => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const { feedbackOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const theme: any = useTheme()

  const onClose = () => {
    dispatch({ type: 'openFeedback', payload: false })
  }

  return (
    <SideDrawer open={feedbackOpen} onBackDropClicked={onClose}>
      <IconButton
        aria-label="close"
        size="small"
        onClick={onClose}
        disableRipple
        sx={{
          ...styles.closeIcon,
          color: theme.colors.grey,
          border: `1px solid ${theme.colors.grey}`
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>

      {feedbackSubmitted ? (
        <FeedbackThanks
          setFeedbackSubmitted={setFeedbackSubmitted}
          onClose={onClose}
        />
      ) : (
        <FeedbackDrawer setFeedbackSubmitted={setFeedbackSubmitted} />
      )}
    </SideDrawer>
  )
}

const Stars: React.FC<StarsProps> = ({
  feedbackRating,
  index,
  setFeedbackRating
}) => {
  const starValues = [1, 2, 3, 4, 5]
  const theme: any = useTheme()
  const [hoveredValue, setHoveredValue] = useState<number>(0)
  const handleStarClick = (event: any) => {
    setFeedbackRating((stars: number[]) => {
      const _stars = [...stars]
      _stars[index] = event.target.value
      return _stars
    })
  }

  const StarIcon = createSvgIcon(
    <path d={outlineStar} color="currentColor" />,
    'Star'
  )
  const StarTwoToneIcon = createSvgIcon(
    <svg>
      <path d={insideStar} color={theme.colors.primary} />
      <path d={outlineStar} color="currentColor" />
    </svg>,
    'StarTwoTone'
  )

  const onHover: (
    starNumber: number
  ) => React.MouseEventHandler<HTMLButtonElement> = (starNumber) => () => {
    setHoveredValue(starNumber)
  }

  return (
    <Flex sx={{ justifyContent: 'center' }}>
      {starValues.map((starValue) => {
        return (
          <IconButton
            aria-label="star"
            onClick={handleStarClick}
            disableRipple
            sx={{
              color:
                starValue <= hoveredValue
                  ? theme.colors.cyan
                  : theme.colors.primary,
              '&:hover': {
                color: theme.colors.cyan
              }
            }}
            onMouseOver={onHover(starValue)}
            onMouseLeave={() => {
              setHoveredValue(0)
            }}
            value={starValue}
            key={starValue}
          >
            {feedbackRating >= starValue ? (
              <StarTwoToneIcon
                sx={{
                  pointerEvents: 'none',
                  color: theme.colors.cyan
                }}
              />
            ) : (
              <StarIcon
                sx={{
                  pointerEvents: 'none'
                }}
              />
            )}
          </IconButton>
        )
      })}
    </Flex>
  )
}

const FeedbackDrawer: React.FC<FeedbackDrawerProps> = ({
  setFeedbackSubmitted
}) => {
  const { sendText } = useContext(UneeqContext)
  const { t } = useTranslation()
  const [feedbackRatings, setFeedbackRatings] = useState([0, 0, 0, 0, 0, 0])

  const submitFeedback = () => {
    const feedbackPayload = {
      FeedbackScore: feedbackRatings
    }
    sendText(feedbackPayload)
    setFeedbackRatings([0, 0, 0, 0, 0, 0])
    setFeedbackSubmitted(true)
  }

  return (
    <Flex sx={styles.feedbackBox}>
      <Text sx={styles.headingText}>{t('endSession.Feedback.heading')}</Text>
      <Text sx={styles.subHeadingText}>
        {t('endSession.Feedback.subHeading')}
      </Text>
      <Flex sx={styles.questionBox}>
        {feedbackRatings.map((feedbackRating, index) => {
          return (
            <Box sx={styles.indQuestion} key={index}>
              <Text sx={styles.questionText}>
                {t(`endSession.Feedback.questions.q${index + 1}.part1`)}
                <Box as="span" color="primary">
                  {` ${config.avatarName} `}
                </Box>
                {t(`endSession.Feedback.questions.q${index + 1}.part2`)}
              </Text>
              <Stars
                setFeedbackRating={setFeedbackRatings}
                index={index}
                feedbackRating={feedbackRating}
              />
            </Box>
          )
        })}
      </Flex>
      <Flex>
        <Button sx={styles.submitButton} onClick={submitFeedback}>
          <Text>{t('endSession.Feedback.submit')}</Text>
        </Button>
      </Flex>
    </Flex>
  )
}

const FeedbackThanks: React.FC<ThanksProps> = ({
  setFeedbackSubmitted,
  onClose
}) => {
  const { t } = useTranslation()

  const closeFeedback = () => {
    onClose()
    setFeedbackSubmitted(false)
  }

  return (
    <Flex
      sx={{ ...styles.feedbackBox, justifyContent: 'center', height: '100vh' }}
    >
      <Text sx={{ ...styles.headingText, width: '180px', textAlign: 'center' }}>
        {t('endSession.Feedback.thanksMessage')}
      </Text>
      <Button sx={styles.submitButton} onClick={closeFeedback}>
        <Text>{t('endSession.Feedback.ok')}</Text>
      </Button>
    </Flex>
  )
}
