import React from 'react'
import { Button, Text } from 'rebass'
import styles from './styles'
import { theme } from 'digital-human/src/theme'

interface ChangeResponseButtonProps {
  text: string
  onClick: () => void
}

export const ChangeResponseButton: React.FC<ChangeResponseButtonProps> = ({
  text,
  onClick
}) => {
  return (
    <Button
      sx={{
        ...styles.toggleInputModeButton,
        backgroundColor: theme.colors.interfaceButtonColor,
        display: 'flex',
        mx: 2,
        width: ['auto', 'auto', 300, 300, 300, 300],
        ml: ['4px', '4px', '8px', '8px', '8px', '8px'],
        mr: ['4px', '4px', '8px', '8px', '8px', '8px']
      }}
      onClick={onClick}
    >
      <Text
        sx={{
          ...styles.inputToggleText,
          textTransform: 'uppercase'
        }}
        as="span"
      >
        {text}
      </Text>
    </Button>
  )
}
