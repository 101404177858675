export const styles = {
  loading: {
    container: {
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        display: 'none'
      },
      '& *': {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      alignItems: 'center',
      minWidth: '100%',
      height: '100%',
      position: 'absolute',
      flexDirection: 'row',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'normal',
        'normal'
      ],
      width: '100%',
      '& > video': {
        objectFit: 'cover',
        width: '100vw',
        height: [
          'calc(100vh - 48px)',
          'calc(100vh - 48px)',
          '100vh',
          '100vh',
          '100vh'
        ],
        minHeight: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 0
      },
      maxHeight: '100%'
    },
    titleMessage: {
      zIndex: 1,
      width: '100%',
      textAlign: 'center',
      fontSize: '40px',
      textTransform: 'uppercase',
      fontWeight: 'semibold',
      color: 'primary'
    },
    subtitleMessage: {
      zIndex: 1,
      width: '100%',
      textAlign: 'center',
      fontSize: '16px',
      color: 'text',
      lineHeight: '20px',
      display: 'block'
    },
    spinnerBox: {
      flexDirection: 'column',
      height: '80px',
      width: '80px',
      mb: ['24px', '24px', '24px', '24px', '0px', '0px']
    },
    messageBox: {
      flexDirection: 'column',
      background: ['white', 'white', 'white', 'white', 'none', 'none'],
      p: '24px',
      width: ['100%', '100%', '100%', '100%', '65%', '65%']
    }
  },
  avatarUnavailable: {
    icon: {
      mb: 7,
      display: 'block',
      color: 'warning'
    },
    heading: {
      display: 'inline-flex',
      width: 'fit-content',
      fontSize: 5
    },
    message: {
      my: 8,
      fontSize: 2
    }
  },
  textContainer: {
    label: 'text-container',
    mb: 0,
    ml: ['0', '0', '0', '0', 'auto', 'auto'],
    alignContent: 'center',
    alignItems: 'center',
    mt: 0,
    display: 'block',
    maxWidth: ['100%', '100%', '100%', '100%', '45%', '40%'],
    width: '100%',
    color: 'primary',
    textAlign: ['center', 'center', 'center', 'center', 'left', 'left'],
    zIndex: 4,
    maxHeight: '100%',
    overflowY: 'scroll',
    alignSelf: [
      'flex-end',
      'flex-end',
      'flex-end',
      'flex-end',
      'center',
      'center'
    ],
    flexDirection: 'column',
    justifyContent: 'center',
    mr: [0, 0, 0, 0, '60px', '60px']
  },
  horizontalDivider: {
    border: 'solid',
    borderColor: 'lightGrey',
    borderWidth: 'thin',
    zIndex: 1,
    width: '100%',
    my: 3,
    visibility: ['hidden', 'hidden', 'hidden', 'hidden', 'visible', 'visible']
  }
}
export default styles
