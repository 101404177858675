import React, { useContext, useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import styles from './styles'

import { Flex, Box, Text, Link } from 'rebass'
import { UneeqContext, useUneeqState, useIsSmallScreen } from 'uneeq-react-core'

const chatDisclaimer = {
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 'medium',
  color: 'text'
}

const BottomBanner: React.FC = () => {
  const { showDisclaimerBanner } = useUneeqState()
  const {
    config: { avatarName },
    dispatch
  } = useContext(UneeqContext)
  const [height, setHeight] = useState(0)

  const isSmallScreen = useIsSmallScreen()
  const { ref: bannerRef } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height: newHeight }) => {
      if (!newHeight || height === newHeight) {
        return
      }

      dispatch({ type: 'setDisclaimerBannerHeight', payload: newHeight })
      setHeight(newHeight)
    }
  })

  if (!showDisclaimerBanner) {
    return null
  }

  return (
    <Flex
      sx={{
        ...styles.bottomBanner,
        background:
          isSmallScreen && showDisclaimerBanner
            ? 'white'
            : 'rgba(255,255,255,0.8)'
      }}
      ref={bannerRef}
    >
      <Flex sx={styles.textWrapper}>
        <Text sx={chatDisclaimer}>
          Sanofi or
          <Box as="span">{` ${avatarName} `}</Box>
          does not provide medical advice, diagnosis, or treatment.
        </Text>
        <Text sx={chatDisclaimer}>
          Please consult your doctor for any medical advice or to talk about
          your experience with MULTAQ.
        </Text>
      </Flex>
      <Flex sx={styles.textWrapper}>
        <Text sx={chatDisclaimer}>
          You may have certain rights under applicable data privacy laws
          regarding the personal information that you provide to Sanofi,
          including the right to access your personal information held by
          Sanofi. For further information regarding these rights, please
          reference the{' '}
          <Link
            sx={{
              textDecoration: 'none',
              color: 'primary',
              fontWeight: 'bold'
            }}
            href="https://www.sanofi.us/en/legal-notice"
            target="_blank"
          >
            Sanofi U.S. Privacy Policy
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export default BottomBanner
