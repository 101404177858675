import { DigitalHuman, PasscodeOverlay } from 'uneeq-react-ui'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box } from 'rebass'
import { getReturningUser } from 'digital-human/src/app/Storage/storage'
import { useWindowWidth } from '@react-hook/window-size'

import { ThemeProvider } from 'emotion-theming'

import theme from '../theme'
import config from '../config'
import Home from './Home'
import assets from './assets'

import { testState } from 'uneeq-react-core'
import SessionTimedOut from './SessionTimedOut'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  minHeight: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  background:
    'radial-gradient(circle, rgba(156,166,179,1) 0%, rgba(204,204,204,1) 100%)',
  overflow: 'hidden',
  position: 'absolute'
}

const postMessage = (message: { [key: string]: any }) =>
  window.parent.postMessage(message, '*')

const setParentHeight = (height: string) => postMessage({ height })

const loadingTips = {
  welcome: {
    title: 'Welcome!',
    subTitles: {
      onmultaq:
        'I can give you more info about MULTAQ and the SmartTraQ\u2122 program to help you get started with your treatment.',
      notonmultaq:
        "I can help you learn about AFib and how it's managed, along with tips on how to talk to your doctor about treatment.",
      notsure:
        "I can help you learn more about how AFib is managed and figure out if one of the medicines you're taking is MULTAQ"
    },
    videos: {
      onmultaq: {
        videoWebmMobile: '/videos/LoadingVideos/Mobile/mobile-welcome1.webm',
        videoMP4Mobile: '/videos/LoadingVideos/Mobile/mobile-welcome1.mp4',
        videoWebmDesktop: '/videos/LoadingVideos/Desktop/desktop-welcome1.webm',
        videoMP4Desktop: '/videos/LoadingVideos/Desktop/desktop-welcome1.mp4'
      },
      notonmultaq: {
        videoWebmMobile: '/videos/LoadingVideos/Mobile/mobile-welcome2.webm',
        videoMP4Mobile: '/videos/LoadingVideos/Mobile/mobile-welcome2.mp4',
        videoWebmDesktop: '/videos/LoadingVideos/Desktop/desktop-welcome2.webm',
        videoMP4Desktop: '/videos/LoadingVideos/Desktop/desktop-welcome2.mp4'
      },
      notsure: {
        videoWebmMobile: '/videos/LoadingVideos/Mobile/mobile-welcome3.webm',
        videoMP4Mobile: '/videos/LoadingVideos/Mobile/mobile-welcome3.mp4',
        videoWebmDesktop: '/videos/LoadingVideos/Desktop/desktop-welcome3.webm',
        videoMP4Desktop: '/videos/LoadingVideos/Desktop/desktop-welcome3.mp4'
      }
    }
  },
  welcomeBack: {
    title: 'Welcome Back!',
    subTitles: {
      onmultaq:
        "Let's see what else I can help you learn about MULTAQ as you get started with treatment."
    },
    videos: {
      onmultaq: {
        videoWebmMobile: '/videos/LoadingVideos/Mobile/mobile-welcomeback.webm',
        videoMP4Mobile: '/videos/LoadingVideos/Mobile/mobile-welcomeback.mp4',
        videoWebmDesktop:
          '/videos/LoadingVideos/Desktop/desktop-welcomeback.webm',
        videoMP4Desktop: '/videos/LoadingVideos/Desktop/desktop-welcomeback.mp4'
      }
    }
  },
  goodToSeeYou: {
    title: 'Good to see you again!',
    subTitles: {
      notonmultaq: "Let's see what questions I can answer about AFib.",
      notsure: "Let's see what questions I can answer about AFib."
    },
    videos: {
      notonmultaq: {
        videoWebmMobile:
          '/videos/LoadingVideos/Mobile/mobile-goodtoseeyou.webm',
        videoMP4Mobile: '/videos/LoadingVideos/Mobile/mobile-goodtoseeyou.mp4',
        videoWebmDesktop:
          '/videos/LoadingVideos/Desktop/desktop-goodtoseeyou.webm',
        videoMP4Desktop:
          '/videos/LoadingVideos/Desktop/desktop-goodtoseeyou.mp4'
      }
    }
  }
}

const muiTheme = createTheme({
  typography: {
    fontFamily: 'inherit'
  }
})

const App = () => {
  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')
  const width = useWindowWidth()
  const isMobile = width < 1024

  // passcode is an overlay, not a step
  const [showPasscode, setShowPasscode] = useState(false)
  const [medicalStatus, setMedicalStatus] = useState<string>('')

  const tokenRef = useRef<string>()
  const start = () => {
    postMessage({ option: medicalStatus, returningUser: getReturningUser() })

    // Show passcode overlay or go straight to digitalHuman
    if (config.usePasscode) {
      setShowPasscode(true)
    } else {
      goTo('digitalHuman')
    }
  }

  const startWithToken = (token: string) => {
    tokenRef.current = token
    goTo('digitalHuman')
    setShowPasscode(false)
  }

  const restart = () => window.location.reload()

  const setHeight = useCallback(
    (height?: string) => {
      if (height) {
        return setParentHeight(height)
      }

      if (isMobile) {
        return setParentHeight('760px')
      }

      setParentHeight('550px')
    },
    [isMobile]
  )

  useEffect(() => {
    if (isMobile && step === 'welcome') {
      return setHeight('1000px')
    }

    setHeight()
  }, [isMobile, setHeight, step])

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Box
          sx={{
            ...backgroundStyle,
            overflow: step === 'welcome' ? 'visible' : 'hidden'
          }}
        >
          {step === 'digitalHuman' ? (
            <DigitalHuman
              assets={assets}
              config={config}
              token={tokenRef.current}
              loadingTips={loadingTips}
              onTimedOut={() => goTo('timed-out')}
              medicalStatus={medicalStatus}
              // postInit={postInit}
              restart={restart}
              // CustomFeedback={CustomFeedback} // uncomment to enable custom Feedback screen
              onSessionEnded={() => goTo('restart')}
              setHeight={setHeight}
            />
          ) : step === 'timed-out' ? (
            <SessionTimedOut restart={restart} />
          ) : (
            <Home
              medicalStatus={medicalStatus}
              setMedicalStatus={setMedicalStatus}
              startSession={start}
            />
          )}
          {showPasscode && (
            <PasscodeOverlay
              start={startWithToken}
              close={() => setShowPasscode(false)}
              config={config}
            />
          )}
        </Box>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}

export default App
