import theme from 'digital-human/src/theme'

export const styles = {
  closeIcon: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    border: '1px solid grey'
  },
  headingText: {
    color: theme.colors.primary,
    textTransform: 'uppercase',
    pb: '10px',
    fontWeight: '700'
  },
  subHeadingText: {
    color: theme.colors.grey,
    textAlign: 'center'
  },
  questionText: {
    color: theme.colors.primary,
    fontWeight: '500'
  },
  feedbackBox: {
    flexDirection: 'column',
    alignItems: 'center',
    width: ['100%', '100%', '100%', '100%', '380px', '380px'],
    pl: '20px',
    pr: '20px',
    pt: '20px'
  },
  questionBox: {
    flexDirection: 'column',
    alignItems: 'center',
    pt: '10px'
  },
  indQuestion: {
    textAlign: 'center'
  },
  submitButton: {
    width: '180px',
    mt: '20px',
    textTransform: 'uppercase'
  }
}
