import React, { useContext, useEffect } from 'react'
import {
  UneeqProvider,
  UneeqAvatar,
  UneeqLocalVideo,
  UneeqContext,
  useUneeqState,
  useIsSmallScreen,
  Uneeq
} from 'uneeq-react-core'
import { Box } from 'rebass'
import BottomBar from './Components/BottomBar'
import OnScreenInfo from './Components/OnScreenInfo'
import Transcript from './Components/Transcript'
import Settings from './Components/Settings'
import Timeout from './Components/Timeout'
import EscalationForm from './Components/endSession/EscalationForm'
import PrivacySummary from './Components/PrivacySummary'
import PermissionsGateway, {
  LoadingTips
} from './Components/Startup/PermissionsGateway'
import { useEvent } from 'react-use'

import EndSessionConfirm from './Components/endSession/EndSessionConfirm'
import styles from './styles'
import { UneeqCoreConfig } from 'uneeq-react-core'
import HelpDrawer from './Components/Drawers/HelpDrawer'
import Feedback from './Components/Drawers/FeedbackDrawer'
import SideBar from './Components/SideBar/SideBar'

const allowedDispatchTypes = ['openTranscript', 'openFeedback', 'openHelp']

const DigitalHumanContent = ({
  restart,
  permissions,
  audio,
  loadingTips,
  medicalStatus,
  setHeight
}: any) => {
  const { dispatch } = useContext(UneeqContext)
  const { showDisclaimerBanner, disclaimerBannerHeight } = useUneeqState()
  const isSmallScreen = useIsSmallScreen()

  useEvent('message', ({ data }) => {
    const { action, target } = data || {}
    const { type } = action || {}
    const actionTypeAllowed = allowedDispatchTypes.includes(type)

    if (target === 'digital-human' && actionTypeAllowed) {
      dispatch(action)
    }
  })

  useEffect(() => {
    // Update uneeq state with medical status
    dispatch({ type: 'updateMedicalStatus', payload: medicalStatus })
  }, [dispatch, medicalStatus])

  const expandedHeight = showDisclaimerBanner && isSmallScreen

  useEffect(() => {
    if (!setHeight) {
      return
    }

    if (expandedHeight) {
      return setHeight('860px')
    }

    // default height
    setHeight()
  }, [expandedHeight, isSmallScreen, setHeight])

  return (
    <Box sx={styles.container}>
      <PermissionsGateway
        restart={restart}
        loadingTips={loadingTips}
        video={permissions}
      >
        <HelpDrawer />
        <OnScreenInfo />
        <Transcript />
        <BottomBar />
        <SideBar />

        {/* Modals */}
        <Settings audio={audio} />
        <Timeout />
        <PrivacySummary />
        <EndSessionConfirm restart={restart} />
        {/* <FinalFeedback
          restart={restart}
          close={() => dispatch({ type: 'openFeedback', payload: false })}
          isOpen={feedbackOpen}
        /> */}
        <Feedback />
        <EscalationForm restart={restart} />
      </PermissionsGateway>

      <UneeqAvatar
        sx={{
          height: expandedHeight
            ? `calc(100% - ${disclaimerBannerHeight}px)`
            : '100%'
        }}
      />

      {/* Must be present but we want it hidden */}
      <UneeqLocalVideo style={styles.localVideo} />
    </Box>
  )
}

interface DigitalHumanProps {
  assets: any
  onTimedOut: () => void
  onSessionEnded: () => void
  config: Partial<UneeqCoreConfig>
  postInit?: (uneeq: Uneeq) => void
  token?: string
  restart: () => void
  loadingTips: LoadingTips
  CustomFeedback?: any
  medicalStatus: string
  setHeight?: (height?: string) => void
}

const DigitalHuman: React.FC<DigitalHumanProps> = ({
  assets,
  onTimedOut,
  onSessionEnded,
  restart,
  postInit,
  config,
  token,
  loadingTips,
  medicalStatus,
  CustomFeedback,
  setHeight
}) => {
  const {
    video: { permissions },
    audio
  } = assets

  return (
    <UneeqProvider
      config={config}
      token={token}
      postInit={postInit}
      onTimedOut={onTimedOut}
      onSessionEnded={onSessionEnded}
      medicalStatus={medicalStatus}
    >
      <DigitalHumanContent
        CustomFeedback={CustomFeedback}
        restart={restart}
        audio={audio}
        permissions={permissions}
        loadingTips={loadingTips}
        medicalStatus={medicalStatus}
        setHeight={setHeight}
      />
    </UneeqProvider>
  )
}

export default DigitalHuman
