import React, { useContext } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'

import styles from './styles'
import { useTheme } from 'emotion-theming'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { Box } from 'rebass'

import SideDrawer from '../SideDrawer'
import HelpDrawerContext from './HelpDrawerContent'

const HelpDrawer: React.FC = () => {
  const { helpOpen } = useUneeqState()
  const theme: any = useTheme()
  const { dispatch } = useContext(UneeqContext)

  const onClose = () => {
    dispatch({ type: 'updateHelpOpen', payload: false })
  }

  return (
    <SideDrawer open={helpOpen} onBackDropClicked={onClose}>
      {/* TODO: Replace with whatever use for other close buttons with the same style */}
      <IconButton
        aria-label="close"
        size="small"
        onClick={onClose}
        disableRipple
        sx={{
          ...styles.closeIcon,
          color: theme.colors.grey,
          border: `1px solid ${theme.colors.grey}`
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>

      <Box sx={styles.drawerContainer}>
        <HelpDrawerContext />
      </Box>
    </SideDrawer>
  )
}
export default HelpDrawer
