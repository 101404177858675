import React from 'react'
import styles from './styles'

import { Flex, SxStyleProp } from 'rebass'

interface SelectProps {
  sx?: SxStyleProp
}

const BottomBanner: React.FC<SelectProps> = ({ children, sx }) => {
  return (
    <Flex
      sx={{
        ...styles.bottomBanner,
        ...sx
      }}
    >
      {children}
    </Flex>
  )
}

export default BottomBanner
