import React, { useContext } from 'react'
import { Box, Text } from 'rebass'
import InformationContent from '../OnScreenInfo/InformationContent'
import styles from './styles'
import { TranscriptItem } from './Transcript'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'

interface TranscriptMessageProps {
  line: TranscriptItem
}
const TranscriptMessage: React.FC<TranscriptMessageProps> = ({ line }) => {
  const {
    config: { avatarName }
  } = useContext(UneeqContext)
  const { username } = useUneeqState()

  const messageStyle = line.user
    ? styles.message
    : {
        ...styles.message,
        textAlign: 'right'
      }

  return (
    <Box
      sx={messageStyle}
      className={line.user ? 'user-message' : 'avatar-message'}
    >
      {line.information ? (
        <InformationContent information={line.information} />
      ) : (
        <Box>
          <Text
            color="primary"
            fontWeight="bold"
            sx={{ pb: 1, textTransform: 'uppercase ' }}
          >
            {line.user ? username || 'You' : avatarName}
          </Text>
          <Text>{line.message}</Text>
        </Box>
      )}
    </Box>
  )
}

export default TranscriptMessage
