import testMP3 from './assets/audio/test.mp3'

const assets = {
  img: {},
  audio: {
    testMP3
  },
  video: {
    permissions: {
      permissionsMP4: 'videos/permissionsVideo.mp4',
      permissionsWebm: 'videos/permissionsVideo.webm'
    }
  }
}

export default assets
