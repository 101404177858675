import React, { useContext } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { Flex, Text, Box } from 'rebass'
import { useTheme } from 'emotion-theming'
import {
  useUneeqState,
  useUneeqEnableMicrophone,
  UneeqContext
} from 'uneeq-react-core'
import PermissionsPrompt, { PermissionsVideo } from '../PermissionsPrompt'
import PermissionsRejected from '../PermissionsRejected'
import AvatarUnavailable from './AvatarUnavailable'
import styles from './styles'
import Spinner from 'react-spinkit'
import { isIOS } from 'react-device-detect'
import {
  getReturningUser,
  setReturningUser
} from 'digital-human/src/app/Storage/storage'

export interface LoadingTips {
  welcome: LoadingMessages
  welcomeBack: LoadingMessages
  goodToSeeYou: LoadingMessages
}

interface VideoSet {
  videoWebmMobile: string
  videoMP4Mobile: string
  videoWebmDesktop: string
  videoMP4Desktop: string
}

interface LoadingMessages {
  title: string
  subTitles: {
    onmultaq?: string
    notonmultaq?: string
    notsure?: string
  }
  videos: {
    onmultaq?: VideoSet
    notonmultaq?: VideoSet
    notsure?: VideoSet
  }
  img?: string
}

interface LoadingProps {
  loadingTips: LoadingTips
}

const Loading: React.FC<LoadingProps> = ({ loadingTips }) => {
  const width = useWindowWidth()
  const { playWelcomeMessage, dispatch } = useContext(UneeqContext)
  const theme = useTheme() as any

  const { medicalStatus } = useUneeqState()
  const returningUser = getReturningUser()

  const genComponents = (
    medicalStatus: keyof LoadingMessages['subTitles'],
    returningUser: boolean
  ) => {
    if (!returningUser) {
      const { title, subTitles, videos } = loadingTips.welcome
      const subTitle = subTitles[medicalStatus] ?? ''
      const {
        videoWebmMobile,
        videoMP4Mobile,
        videoWebmDesktop,
        videoMP4Desktop
      } = videos[medicalStatus] ?? {}
      return {
        title,
        subTitle,
        videoWebmMobile,
        videoMP4Mobile,
        videoWebmDesktop,
        videoMP4Desktop
      }
    }

    if (medicalStatus === 'onmultaq') {
      const { title, subTitles, videos } = loadingTips.welcomeBack
      const subTitle = subTitles.onmultaq ?? ''
      const {
        videoWebmMobile,
        videoMP4Mobile,
        videoWebmDesktop,
        videoMP4Desktop
      } = videos.onmultaq ?? {}
      return {
        title,
        subTitle,
        videoWebmMobile,
        videoMP4Mobile,
        videoWebmDesktop,
        videoMP4Desktop
      }
    }

    const title = loadingTips.goodToSeeYou.title
    const subTitle = loadingTips.goodToSeeYou.subTitles.notonmultaq ?? ''
    const {
      videoWebmMobile,
      videoMP4Mobile,
      videoWebmDesktop,
      videoMP4Desktop
    } = loadingTips.goodToSeeYou.videos.notonmultaq ?? {}
    return {
      title,
      subTitle,
      videoWebmMobile,
      videoMP4Mobile,
      videoWebmDesktop,
      videoMP4Desktop
    }
  }

  const {
    title,
    subTitle,
    videoWebmMobile,
    videoMP4Mobile,
    videoWebmDesktop,
    videoMP4Desktop
  } = genComponents(medicalStatus, returningUser)

  const smallScreen = width < 1024
  const poster = smallScreen ? '/images/idle-mobile.jpg' : '/images/idle.jpg'

  const videoFinished = () => {
    playWelcomeMessage()
    setReturningUser(true)
    dispatch({ type: 'loadingFinished' })
    dispatch({
      type: 'uneeqMessage',
      payload: {
        uneeqMessageType: 'SessionLive'
      }
    })
  }

  return (
    <Flex sx={styles.loading.container}>
      <video
        playsInline
        autoPlay
        muted={isIOS}
        controls={isIOS}
        key={poster}
        poster={poster}
        onEnded={videoFinished}
      >
        <source
          src={smallScreen ? videoWebmMobile : videoWebmDesktop}
          type="video/webm"
        />
        <source
          src={smallScreen ? videoMP4Mobile : videoMP4Desktop}
          type="video/mp4"
        />
      </video>

      <Flex sx={styles.textContainer}>
        <Flex sx={styles.loading.spinnerBox}>
          <Spinner
            name="ball-spin-fade-loader"
            color={theme.colors.cyan}
            fadeIn="none"
            style={{ top: '32px', left: '32px' }}
          />
        </Flex>
        <Flex sx={styles.loading.messageBox}>
          <Text sx={styles.loading.titleMessage}>{title}</Text>
          <Box as="hr" sx={styles.horizontalDivider} />
          <Text sx={styles.loading.subtitleMessage}>{subTitle}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

interface PermissionsGatewayProps {
  restart: () => void
  children: any
  loadingTips: LoadingTips
  video: PermissionsVideo
}

const PermissionsGateway: React.FC<PermissionsGatewayProps> = ({
  restart,
  children,
  loadingTips,
  video
}) => {
  const { ready, unavailable, permissionAllowed } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const enableMicrophone = useUneeqEnableMicrophone()

  const dismissPermissionsRejected = () => {
    dispatch({ type: 'approved' })
  }

  const retryPermissions = () => {
    enableMicrophone(true)
    dismissPermissionsRejected()
  }

  return permissionAllowed === null ? (
    <PermissionsPrompt video={video} />
  ) : permissionAllowed === false ? (
    <PermissionsRejected
      restart={restart}
      dismiss={dismissPermissionsRejected}
      retry={retryPermissions}
    />
  ) : permissionAllowed && ready ? (
    children
  ) : unavailable ? (
    <AvatarUnavailable restart={restart} />
  ) : (
    // UneeQ is not ready or
    // Permissions are completely unknown
    // They could be aproved, rejected or neither
    // Show loader for now..
    <Loading loadingTips={loadingTips} />
  )
}

export default PermissionsGateway
