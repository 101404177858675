import React, { useContext, useRef, useCallback, useEffect } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import {
  trackHandler,
  UneeqContext,
  useUneeqSpaceToTalk,
  useUneeqEnableMicrophone,
  useUneeqState
} from 'uneeq-react-core'
import { useClickAway } from 'react-use'
import { ReactComponent as SkipIcon } from '../../assets/img/skip.svg'
import Chat from './Chat'
import PushToTalk from './PushToTalk'
import styles from './styles'
import SuggestedResponses from '../OnScreenInfo/SuggestedResponses'
import ReactTooltip from 'react-tooltip'
import { useInputProblem } from './useInputProblem'
import { useMicPermissionsGranted } from './useMicPermissionsGranted'

import { useTranslation } from 'react-i18next'
import BottomBanner from '../BottomBanner/BottomBanner'
import { ChangeResponseButton } from './ChangeResponseButton'

interface InputToggleProps {
  speechMode: boolean
  toggleInputMode: () => void
}
const InputToggle: React.FC<InputToggleProps> = ({
  speechMode,
  toggleInputMode
}) => {
  const { t } = useTranslation()
  return (
    <Button
      sx={{
        ...styles.toggleInputModeButton,
        backgroundColor: 'rgb(33,77,159)',
        display: speechMode
          ? 'flex'
          : ['none', 'none', 'none', 'none', 'flex', 'flex']
      }}
      onClick={toggleInputMode}
    >
      <Text sx={styles.inputToggleText} as="span">
        {speechMode
          ? t('BottomBar.BottomBar.type')
          : t('BottomBar.BottomBar.talk')}
      </Text>
    </Button>
  )
}

const BottomBar: React.FC = () => {
  const {
    recording,
    sending,
    startRecording,
    stopRecording
  } = useUneeqSpaceToTalk()

  const enableMicrophone = useUneeqEnableMicrophone()

  const {
    inputMode,
    onScreenInfo,
    spacebarTapped,
    noInput,
    question,
    avatarAnswerReceived,
    showChangeResponseButton,
    typeModeFromBackend,
    conversationStarted,
    conversationEnded,
    showDisclaimerBanner
  } = useUneeqState()

  const pttRef = useRef<Element>()
  const { dispatch, sendText } = useContext(UneeqContext)
  const { t } = useTranslation()

  const micPermsGranted = useMicPermissionsGranted()

  const speechMode = inputMode === 'speech'

  const toggleInputMode = useCallback(
    () =>
      !typeModeFromBackend &&
      dispatch({
        type: 'setInputMode',
        payload: speechMode ? 'text' : 'speech'
      }),
    [speechMode, typeModeFromBackend, dispatch]
  )

  const inputModeContainer = useRef(null)
  useClickAway(inputModeContainer, toggleInputMode)

  const inputError = spacebarTapped ? 'spacebarTapped' : noInput && 'noInput'

  const errorMessage = useInputProblem(inputError)

  useEffect(() => {
    if (!pttRef.current) {
      return
    }

    inputError
      ? ReactTooltip.show(pttRef.current)
      : ReactTooltip.hide(pttRef.current)
  }, [pttRef, inputError, spacebarTapped])

  const shouldShowQuestion =
    question && !onScreenInfo?.suggestedResponses?.chosenResponse

  const shouldShowSuggestedResponses =
    onScreenInfo?.suggestedResponses &&
    !onScreenInfo?.suggestedResponses?.sideLayout

  const shouldShowNextStepsButton =
    onScreenInfo?.nextSuggestedResponses?.suggestedResponses?.length > 0

  const startRecordingWithPermissions = () => {
    if (!micPermsGranted) {
      enableMicrophone(true) // prompt user with dialog only
      return
    }

    startRecording()
  }

  const stopRecordingWithPermissions = () => {
    if (!micPermsGranted) {
      return
    }

    stopRecording()
  }

  return (
    <Flex
      sx={{
        ...styles.bar,
        mb: showDisclaimerBanner
          ? '0px'
          : ['24px', '24px', '24px', '24px', '36px', '36px']
      }}
    >
      <Flex sx={styles.left} />

      <Flex
        sx={{
          ...styles.center,
          alignItems:
            shouldShowQuestion || avatarAnswerReceived ? 'center' : 'normal'
        }}
      >
        <Box
          sx={styles.motionSuggestedResponses}
          display={shouldShowSuggestedResponses ? undefined : 'none !important'}
        >
          <SuggestedResponses />
        </Box>

        {avatarAnswerReceived && shouldShowNextStepsButton ? (
          <Button
            variant="primaryInverted"
            sx={styles.skip}
            onClick={() => dispatch({ type: 'skip' })}
          >
            <SkipIcon />
            <span>Show next steps</span>
          </Button>
        ) : (
          <Flex sx={styles.pttOuterContainer}>
            {speechMode && conversationStarted && !conversationEnded && (
              <Flex sx={styles.pttContainer}>
                <Flex sx={{ minWidth: 'auto' }}>
                  <InputToggle
                    speechMode={speechMode}
                    toggleInputMode={trackHandler(
                      toggleInputMode,
                      speechMode
                        ? 'enable-type-mode-btn'
                        : 'disable-type-mode-btn'
                    )}
                  />
                </Flex>
                {showChangeResponseButton && (
                  <Flex sx={{ minWidth: 'auto' }}>
                    <ChangeResponseButton
                      text={t('BottomBar.ChangeResponse.text')}
                      onClick={() => {
                        sendText(t('BottomBar.ChangeResponse.utterance'))
                      }}
                    />
                  </Flex>
                )}

                <Box
                  ref={pttRef}
                  onTouchStart={startRecordingWithPermissions}
                  onTouchEnd={stopRecordingWithPermissions}
                  onMouseDown={startRecordingWithPermissions}
                  onMouseUp={stopRecordingWithPermissions}
                  data-tip
                  data-for="ptt"
                >
                  <PushToTalk recording={recording} sending={sending} />
                </Box>
              </Flex>
            )}
            {!speechMode && (
              <Flex
                sx={styles.chatAndInputToggleContainer}
                ref={inputModeContainer}
              >
                <Chat />
                {!typeModeFromBackend && (
                  <Box
                    onTouchStart={startRecordingWithPermissions}
                    onTouchEnd={stopRecordingWithPermissions}
                    onMouseDown={startRecordingWithPermissions}
                    onMouseUp={stopRecordingWithPermissions}
                  >
                    <PushToTalk recording={recording} sending={sending} />
                  </Box>
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      <Box display={shouldShowNextStepsButton ? 'none' : 'block'}>
        <ReactTooltip
          id="ptt"
          effect="solid"
          event="noEvent"
          backgroundColor="white"
        >
          <Text color="primary" fontWeight={'medium'}>
            {errorMessage}
          </Text>
        </ReactTooltip>
      </Box>
      <BottomBanner />
    </Flex>
  )
}

export default BottomBar
