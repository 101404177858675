import React, { useContext } from 'react'
import { Button, Flex } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { motion, AnimatePresence } from 'framer-motion'
import { styles as s } from './styles'
import { useTheme } from 'emotion-theming'

const MotionButton = motion.custom(Button)
const MotionFlex = motion.custom(Flex)

interface SuggestedResponsesProps {
  sideLayout?: boolean
}
const SuggestedResponses: React.FC<SuggestedResponsesProps> = ({
  sideLayout = false
}) => {
  const { sendText, dispatch } = useContext(UneeqContext)
  const theme: any = useTheme()

  const {
    onScreenInfo: { suggestedResponses }
  } = useUneeqState()

  const styles = sideLayout ? s.suggestedResponsesSide : s.suggestedResponses

  const send = (response: string) => {
    sendText(response)
    dispatch({ type: 'suggestedResponseSent', payload: response })
  }

  const chosen = suggestedResponses?.chosenResponse
  const responses = suggestedResponses?.suggestedResponses

  return (
    <>
      <AnimatePresence>
        {!chosen && (
          <MotionFlex
            variants={{
              start: {
                opacity: 0,
                transform: 'translate(-100px, 0px)'
              },
              end: {
                opacity: 1,
                transform: 'translate(10px, 0px)'
              },
              final: {
                opacity: 0,
                transform: 'translate(100px, 0px)'
              }
            }}
            initial="start"
            animate="end"
            exit="final"
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          ></MotionFlex>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <MotionFlex
          variants={{
            start: {
              transition: {
                delay: 0.8,
                staggerChildren: 0.4
              }
            },
            end: {
              transition: {
                staggerChildren: 0.4
              }
            },
            final: {
              transition: {
                duration: 0.4
              }
            }
          }}
          initial="start"
          transition={{
            duration: 0.3
          }}
          animate="end"
          exit="final"
          sx={styles.itemsContainer}
          key={suggestedResponses?.suggestedResponses[0].utterance}
        >
          <AnimatePresence>
            {responses?.map(
              (response: any, index: number) =>
                (!chosen || response.utterance === chosen) && (
                  <MotionButton
                    transition={{
                      duration: 0.4
                    }}
                    variant="primaryInvertedBorder"
                    variants={{
                      start: {
                        opacity: 0,
                        transform: 'translate(-200px, 0px)',
                        transition: {
                          duration: 0.4
                        }
                      },
                      end: {
                        opacity: 1,
                        transform: 'translate(1px, 0px)'
                      },
                      final: {
                        opacity: 0,
                        transform: 'translate(200px, 0px)'
                      }
                    }}
                    // initial="start"
                    // animate="end"
                    exit="final"
                    key={response.utterance}
                    sx={{
                      ...styles.item,
                      cursor: chosen ? 'auto' : 'pointer'
                    }}
                    onClick={
                      !chosen
                        ? trackHandler(
                            () => send(response.utterance),
                            'suggested-response-btn'
                          )
                        : () => {}
                    }
                  >
                    {sideLayout && (
                      <Flex
                        sx={{
                          ...styles.indexIcon,
                          borderColor: theme.colors.cyan,
                          background: theme.colors.primary,
                          color: theme.colors.primaryText
                        }}
                      >
                        {index + 1}
                      </Flex>
                    )}
                    {response.label}
                  </MotionButton>
                )
            )}
          </AnimatePresence>
        </MotionFlex>
      </AnimatePresence>
    </>
  )
}

export default SuggestedResponses
