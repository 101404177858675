import { useContext, useEffect } from 'react'

import { UneeqContext, useIsSmallScreen } from 'uneeq-react-core'
import { useTranslation } from 'react-i18next'

const useInputProblem = (error = '') => {
  const {
    dispatch,
    config: { inputProblemErrorHiddenAfter }
  } = useContext(UneeqContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (!error) {
      return
    }

    const timeout = setTimeout(() => {
      dispatch({ type: 'clearError' })
    }, inputProblemErrorHiddenAfter)

    return () => clearTimeout(timeout)
  }, [inputProblemErrorHiddenAfter, dispatch, error])

  const isSmallScreen = useIsSmallScreen()

  const spacebarTapped = !isSmallScreen
    ? t('InputProblem.spacebarNotSmallScreen')
    : t('InputProblem.spacebarSmallScreen')

  return error === 'noInput' ? t('InputProblem.noInput') : spacebarTapped
}

export { useInputProblem }
