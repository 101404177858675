const styles = {
  drawerContainer: {
    width: ['100%', '100%', '100%', '100%', '330px'],
    height: '100%',
    px: 4,
    py: 6
  },
  closeIcon: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    border: '1px solid grey'
  }
}

export default styles
