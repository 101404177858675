import React, { useContext } from 'react'
import { UneeqContext } from 'uneeq-react-core'
import { Box, Button, Flex, Image } from 'rebass'
import InformationContent from './InformationContent'
import { styles as s } from './styles'
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'
import { useTranslation } from 'react-i18next'

const styles = s.information

export interface HeadingInformation {
  type: 'heading'
  text: string
}
interface TextInformation {
  type: 'text'
  text: string
}
interface HTMLInformation {
  type: 'html'
  html: string
}
export interface MarkdownInformation {
  type: 'markdown'
  markdown: string
}
interface ImageInformation {
  type: 'image'
  source: string
  label: string
  width: string
}
interface VideoInformation {
  type: 'video'
  source: string
  width: string
  height: string
}
export interface LinkInformation {
  type: 'link'
  href: string
  label: string
}

export type InformationItemWithoutList =
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | LinkInformation
  | MarkdownInformation

interface ListInformation {
  type: 'list'
  items: InformationItemWithoutList[]
}

export type InformationItem =
  | ListInformation
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | LinkInformation
  | MarkdownInformation

interface InformationProps {
  information: InformationItem[]
  invertInformationColors?: boolean
}

const Information: React.FC<InformationProps> = ({
  information,
  invertInformationColors = false
}) => {
  const { dispatch } = useContext(UneeqContext)

  const hide = () => {
    dispatch({ type: 'closeInformation' })
  }

  const linkColor = invertInformationColors ? 'white' : 'primary'
  const textColor = invertInformationColors ? 'white' : undefined

  return (
    <>
      <Button sx={styles.closeButton} variant="unstyled" onClick={hide}>
        <Image
          sx={{ color: invertInformationColors ? 'white' : '#a6adb4' }}
          as={CloseIcon}
          alt=""
        />
      </Button>
      <Box sx={styles.content}>
        <Box
          sx={{
            ...styles.scroll,
            color: textColor,
            '& a': {
              textDecoration: 'none',
              color: linkColor,
              ':visited': {
                color: linkColor
              }
            }
          }}
        >
          <InformationContent
            information={information}
            invertInformationColors={invertInformationColors}
          />
        </Box>
      </Box>
    </>
  )
}

export default Information
