import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Box, Button, Flex, Link, Text } from 'rebass'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { UnsupportedBrowser } from 'uneeq-react-ui'
import config from '../../config'
import styles from './styles'
import typography from '../../typography'
import { SelectChangeEvent } from '@mui/material'
import Dropdown from '../Components/Dropdown'
import BottomBanner from '../Components/BottomBanner/BottomBanner'
import theme from '../../theme'

interface SophieVideoProps {
  width: number
}

const SophieVideo = ({ width }: SophieVideoProps) => {
  const smallScreen = width < 1024
  const poster = smallScreen ? '/images/idle-mobile.jpg' : '/images/idle.jpg'

  return (
    <video playsInline autoPlay muted loop key={poster} poster={poster}>
      <source
        src={smallScreen ? '/videos/idle-mobile.webm' : '/videos/idle.webm'}
        type="video/webm"
      />
      <source
        src={smallScreen ? '/videos/idle-mobile.mp4' : '/videos/idle.mp4'}
        type="video/mp4"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
  setMedicalStatus: React.Dispatch<React.SetStateAction<string>>
  medicalStatus: string
}

const options = [
  { key: 'onmultaq', value: 'ON MULTAQ FOR AFIB' },
  { key: 'notonmultaq', value: 'HAVE AFIB BUT NOT MULTAQ' },
  { key: 'notsure', value: 'HAVE AFIB BUT NOT SURE IF TAKING MULTAQ' }
]

const Home: React.FC<HomeProps> = ({
  startSession,
  medicalStatus,
  setMedicalStatus
}) => {
  const width = useWindowWidth()
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)
  const [buttonError, setButtonError] = useState(false)
  const isExtraLarge = width > 2000
  const isMobile = width < 1024
  const isExtraSmall = width < 400

  const smallHeaderStyling = isExtraSmall
    ? { fontSize: '42px', lineHeight: '42px' }
    : {}
  const smallSubheaderStyling = isExtraSmall
    ? { fontSize: '24px', lineHeight: '24px' }
    : {}

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const medicalEvent = event as SelectChangeEvent<string>
    setButtonError(false)
    setMedicalStatus(medicalEvent.target.value)
  }

  const handleButtonClick = () => {
    if (!medicalStatus) {
      setButtonError(true)
      return
    }

    trackHandler(startSession, 'lets-chat-btn')(true)
    return
  }

  return (
    <Flex
      sx={{
        ...styles.mainContainer,
        flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <SophieVideo width={width} />

      <Flex
        sx={{
          ...styles.textContainer,
          pr: [
            '10px',
            '10px',
            '10px',
            '10px',
            '25px',
            isExtraLarge ? '15%' : '5%'
          ],
          mt: isMobile ? 'auto' : '0'
        }}
      >
        <Flex flexDirection="column" sx={{ gap: 1 }}>
          <Box
            sx={{
              ...styles.titleContainer,
              textAlign: isMobile ? 'center' : 'match-parent'
            }}
          >
            <Text sx={{ ...typography.h1, ...smallHeaderStyling }}>
              MEET
              <Box as="span" color="primary">
                {` ${config.avatarName.toUpperCase()}`}
              </Box>
            </Text>
          </Box>
          <Box
            sx={{
              ...styles.titleContainer,
              textAlign: isMobile ? 'center' : 'match-parent'
            }}
          >
            <Text
              sx={{ ...typography.subhead, ...smallSubheaderStyling }}
              color="primary"
            >
              YOUR AFIB ADVISOR
            </Text>
          </Box>
        </Flex>

        <Box as="hr" sx={styles.seperator} />
        <Text sx={typography.body}>
          <Box as="span">{`${config.avatarName} `}</Box>
          is a virtual advisor here to help you learn more about AFib and
          treatment with MULTAQ. To help you get the right support, pick the
          option that best describes you or someone you care about.
        </Text>

        <Flex justifyContent={isMobile ? 'center' : 'default'}>
          <Dropdown
            id="option-select"
            value={medicalStatus}
            onChange={handleChange}
            displayEmpty
            renderValue={
              medicalStatus !== '' ? undefined : () => 'CHOOSE AN OPTION'
            }
            options={options}
            width="564px"
            fullWidth
            error={buttonError}
            setError={setButtonError}
          />
        </Flex>

        <Text sx={typography.chatDisclaimer}>
          By choosing an option, you are starting a chat with
          <Box as="span">{` ${config.avatarName} `}</Box>
          and agree to the{' '}
          <Link
            sx={typography.disclaimerLink}
            href="https://www.sanofi.us/en/legal-notice/#Privacypolicy"
            target="_blank"
          >
            Sanofi U.S. Privacy Policy
          </Link>
        </Text>

        <Button
          variant="primary"
          onClick={handleButtonClick}
          sx={{
            ...typography.buttonText,
            maxWidth: ['100%', '100%', '100%', '100%', '200px'],
            opacity: 1,
            backgroundColor: !medicalStatus
              ? theme.colors.beginButton
              : theme.colors.primary,
            '&:hover': {
              backgroundColor: !medicalStatus
                ? theme.colors.beginButton
                : theme.colors.primaryLight
            },
            '&:focus': {
              backgroundColor: !medicalStatus
                ? theme.colors.beginButton
                : theme.colors.primary
            }
          }}
          id="begin-button"
        >
          BEGIN
        </Button>
      </Flex>
      <BottomBanner
        sx={{
          position: isMobile ? 'static' : 'absolute',
          background: isMobile ? 'white' : 'rgba(255,255,255,0.8)'
        }}
      >
        <Text sx={typography.chatDisclaimer}>
          Sanofi or
          <Box as="span">{` ${config.avatarName} `}</Box>
          does not provide medical advice, diagnosis, or treatment.
        </Text>
        <Text sx={typography.chatDisclaimer}>
          Please consult your doctor for any medical advice or to talk about
          your experience with MULTAQ.
        </Text>
      </BottomBanner>
    </Flex>
  )
}

export default Home
