import React from 'react'
import { Flex, Text } from 'rebass'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { ReactComponent as DownloadIcon } from '../../assets/img/download.svg'
import { trackHandler } from 'uneeq-react-core'
import styles from './styles'

import { isMobileSafari, isMobile, isChrome } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

interface TranscriptHeaderProps {
  close: () => void
  downloadTranscript: () => void
}
const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({
  close,
  downloadTranscript
}) => {
  const { t } = useTranslation()
  return (
    <Flex
      sx={{
        ...styles.header,
        position:
          isMobileSafari || (isMobile && isChrome) ? 'absolute' : 'static',
        top: isMobileSafari ? 57 : isMobile && isChrome ? 27 : 'auto'
      }}
      className="transcript-header"
    >
      <Flex
        alignItems="center"
        onClick={trackHandler(downloadTranscript, 'download-transcript-btn')}
        sx={styles.download}
      >
        <Flex sx={styles.downloadIcon} className="button">
          <DownloadIcon />
        </Flex>
        <Text sx={styles.headerText}>
          {t('TranscriptHeader.downloadTranscript')}
        </Text>
      </Flex>

      <Flex sx={styles.pinButton} onClick={close} className="button">
        <Text
          className="transcript-tooltip"
          sx={{ ...styles.tooltip, right: 40 }}
        >
          {t('TranscriptHeader.minimise')}
        </Text>
        <Flex sx={styles.pinIcon}>
          <IconButton
            aria-label="close"
            size="small"
            disableRipple
            sx={{
              height: '24px',
              width: '24px',
              color: 'white',
              border: `1px solid white`
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TranscriptHeader
