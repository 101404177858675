import { SxStyleProp } from 'rebass'

type StyleTypes = { [x: string]: SxStyleProp }

export const styles: StyleTypes = {
  bottomBanner: {
    width: '100%',
    py: 20,
    px: 10,
    bottom: 0,
    background: 'rgba(255,255,255,0.8)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 6,
    zIndex: 2
  },
  textWrapper: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1200px',
    textAlign: 'center'
  }
}
export default styles
