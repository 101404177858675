import React from 'react'
import { trackEvent } from 'uneeq-react-core'
import { useWindowWidth } from '@react-hook/window-size'
import Overlay from '../generic/Overlay'

export interface PermissionsVideo {
  permissionsMP4: any
  permissionsWebm: any
}

interface PermissionsPromptProps {
  video: PermissionsVideo
}

const PermissionsPrompt: React.FC<PermissionsPromptProps> = ({ video }) => {
  const width = useWindowWidth()
  const smallScreen = width < 1024
  const poster = smallScreen ? '/images/idle-mobile.jpg' : '/images/idle.jpg'

  trackEvent('permissions-prompt', 'state-change')
  return (
    <Overlay>
      <img
        src={poster}
        style={{
          height: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      />
    </Overlay>
  )
}

export default PermissionsPrompt
