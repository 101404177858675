import { SxStyleProp } from 'rebass'

type StyleTypes = { [x: string]: SxStyleProp }

const styles: StyleTypes = {
  bar: {
    label: 'sideBar',
    position: 'absolute',
    right: 0,
    top: 0,
    width: ['100%', '100%', '100%', '100%', '30%', '30%'],
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: ['2em', '2em', '2em', '2em', '3em', '3em'],
    paddingRight: ['2em', '2em', '2em', '2em', '3em', '3em'],
    alignItems: 'left',
    '& div::-webkit-scrollbar': {
      display: 'none'
    },
    '& div': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    rowGap: '1em',
    zIndex: 4,
    pointerEvents: 'none'
  },
  container: {
    background: ['white', 'white', 'white', 'white', 'none', 'none'],
    padding: 6,
    boxShadow: '0 0 3px var(--box-shadow-color)',
    borderRadius: '3px',
    pointerEvents: 'auto'
  }
}

export default styles
