import { closeDialogs, closeModals } from './initialState'
import {
  UneeqInformation,
  UneeqSuggestedResponses,
  UneeqState,
  Config
} from '../../uneeq'

export type UneeqCommand = {
  openInputBar?: boolean
  openTranscript?: boolean
  openFeedback?: boolean
  openHelp?: boolean
  openEscalationForm?: boolean
  showDisclaimerBanner?: boolean
  inputBarPlaceholderText?: string
  information?: UneeqInformation
  invertInformationColors?: boolean
  textAlign?: string
  showChangeResponseButton?: boolean
  informationPadding?: string
  username?: string
  conversationEnded?: boolean
  informationShowDelay?: number
  informationHideDelay?: number
} & UneeqSuggestedResponses

export const uneeqCommandReducer = (
  state: UneeqState,
  command: UneeqCommand,
  config: Config
) => {
  let newState = { ...state }

  newState.inputMode = command.openInputBar ? 'text' : 'speech'
  newState.typeModeFromBackend = !!command.openInputBar

  if (command.openTranscript) {
    newState = {
      ...newState,
      ...closeDialogs,
      transcriptOpen: command.openTranscript,
      transcriptHasOpened: state.transcriptHasOpened || command.openTranscript
    }
  }
  if (command.openFeedback) {
    newState = {
      ...newState,
      ...closeDialogs,
      ...closeModals,
      feedbackOpen: command.openFeedback
    }
  }
  if (command.openHelp) {
    newState = {
      ...newState,
      ...closeDialogs,
      ...closeModals,
      helpOpen: command.openHelp
    }
  }
  if (command.openEscalationForm) {
    newState = {
      ...newState,
      ...closeDialogs,
      ...closeModals,
      escalationFormOpen: command.openEscalationForm,
      escalationFormFromServer: true
    }
  }
  if (command.suggestedResponses && command.suggestedResponses.length > 0) {
    const { mainTitle, suggestedResponses, sideLayout } = command

    newState.onScreenInfo.nextSuggestedResponses = {
      id: Date.now(),
      mainTitle: mainTitle || '',
      suggestedResponses,
      sideLayout
    }
  }
  if (command.information) {
    let normalizedInformation
    if (typeof command.information === 'string') {
      normalizedInformation = [
        { type: 'markdown', markdown: command.information }
      ]
    } else {
      normalizedInformation = command.information
    }
    // @ts-ignore
    newState.onScreenInfo.information = normalizedInformation

    if (config.informationInTranscript) {
      newState.transcript = [
        ...state.transcript,
        {
          information: normalizedInformation,
          user: false,
          time: new Date().toISOString()
        }
      ]
    }
  } else {
    newState.onScreenInfo.information = undefined
  }

  if (command.username) {
    newState.username = command.username
  }

  newState.showDisclaimerBanner = command.showDisclaimerBanner
  newState.inputBarPlaceholderText = command.inputBarPlaceholderText
  newState.showChangeResponseButton = command.showChangeResponseButton || false
  newState.onScreenInfo.invertInformationColors =
    command.invertInformationColors
  newState.onScreenInfo.textAlign = command.textAlign
  newState.onScreenInfo.informationPadding = command.informationPadding
  newState.informationShowDelay = command.informationShowDelay
  newState.informationHideDelay = command.informationHideDelay
  newState.conversationEnded = command.conversationEnded || false

  return newState
}

export default uneeqCommandReducer
