const returningUserKey = 'returningUser'

export const getReturningUser = () => {
  const returningUserValue = localStorage.getItem(returningUserKey) ?? 'false'
  return returningUserValue === 'true'
}

export const setReturningUser = (value: boolean) => {
  return localStorage.setItem(returningUserKey, value.toString())
}
