const styles = {
  bar: {
    label: 'bottomBar',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    '& div::-webkit-scrollbar': {
      display: 'none'
    },
    '& div': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    }
  },
  lowerBg: {
    position: 'fixed',
    visibility: ['hidden', 'hidden', 'hidden', 'hidden', 'visible', 'visible'],
    bottom: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    maxHeight: 280,
    opacity: 0.5
  },
  left: {
    flex: 1,
    p: 5,
    alignItems: 'center'
  },
  userQuestionMotionContainer: {
    label: 'userQuestionContainer',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1
  },
  motionSuggestedResponses: {
    height: 95,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  center: {
    label: 'bottomBarCenter',
    flexDirection: 'column',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 1
  },
  mobileChatContainer: {
    display: ['flex', 'flex', 'flex', 'flex', 'none'],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto']
  },
  bigScreenChatContainer: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto']
  },
  chatAndInputToggleContainer: {
    label: 'chat-and-input-toggle-container',
    alignItems: 'center',
    gap: '8px'
  },
  userQuestionContainer: {
    width: 521,
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatSavedItemsContainer: {
    label: 'chatSavedItemsContainer',
    position: 'absolute',
    right: 40,
    bottom: 40,
    zIndex: 6
  },
  chatBubbleContainer: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    cursor: 'pointer',
    color: 'primary',
    alignItems: 'center',
    ml: 6,
    '& svg': {
      mr: 1,
      mt: '2px'
    }
  },
  skip: {
    label: 'skip',
    width: 204,
    height: 50,
    minHeight: 50,
    mb: [2, 2, 2, 2, 0]
  },
  pttOuterContainer: {
    justifyContent: 'center',
    minHeight: [40, 40, 70, 70, 50, 50],
    width: '100%',
    mt: 4,
    mb: 4
  },
  pttContainer: {
    label: 'pttContainer',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '8px',
    flexWrap: 'wrap'
  },
  emptyContainer: {
    minWidth: [30, 30, 40, 40, 0, 0],
    height: [30, 30, 40, 40, 0, 0],
    mr: [10, 10, 10, 10, 0, 0]
  },
  mobileContainer: {
    width: [30, 30, 40, 40, 0, 0]
  },
  pushToTalkButton: {
    label: 'pushToTalkButton',
    cursor: 'pointer',
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      mr: 0,
      fill: 'none',
      strokeWidth: 0.5,
      stroke: '#FFFFFF',
      height: 'auto',
      width: 'auto',
      minWidth: 'auto'
    },
    py: 4,
    backgroundClip: 'padding-box'
  },
  pushToTalkText: {
    display: 'inline-flex'
  },
  toggleInputModeButton: {
    borderRadius: 0,
    color: 'white',
    height: 'auto',
    '& svg': {
      width: 'auto'
    },
    py: 4,
    px: 10
  },
  submitButton: {
    borderRadius: 0,
    py: 4,
    px: 8,
    width: 'auto'
  },
  inputToggleText: {
    display: 'inline-flex',
    fontSize: [14, 14, 16, 16, 16, 16]
  },
  mobilePrompt: {
    position: 'absolute',
    bottom: 120,
    width: '100%',
    left: 0,
    color: 'white',
    justifyContent: 'center',
    display: ['flex', 'none'],
    fontSize: 25
  },
  talkButton: {
    label: 'talkButton',
    display: ['block', 'none'],
    borderRadius: '1000px',
    width: '71px',
    height: '71px',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'none',
    mt: -45,
    div: {
      width: '40px',
      height: '40px',
      borderRadius: '40px',
      border: '5px solid black',
      transition: 'none'
    }
  },
  chatContainer: {
    height: 50,

    width: ['100%', '100%', '100%', '100%', 400, 400],
    position: 'relative'
  },
  chatLabel: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden'
  },
  chatTextInput: {
    label: 'chat-text-input',
    fontFamily: 'body',
    border: 'none',
    fontSize: 2,
    pr: 14,
    pl: 8,
    backgroundColor: 'white',
    color: 'text',
    '&::placeholder': {
      opacity: '0.6'
    },
    width: '100%',
    '&:focus': {
      outline: 'none'
    }
  },
  chatTextInputGroup: {
    label: 'chat-text-input-group',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    gap: '8px',
    width: ['85vw', '85vw', '85vw', '85vw', 'auto', 'auto']
  },
  chatSendButton: {
    color: 'primary',
    width: 37,
    height: 37,
    cursor: 'pointer',
    position: 'absolute',
    right: '7px',
    top: '7px'
  },
  toggleContainer: {
    position: 'absolute',
    right: 25,
    bottom: 37,
    display: ['none', 'flex'],
    alignItems: 'center',
    cursor: 'pointer'
  },
  exitSessionIcon: {
    position: 'fixed',
    top: 30,
    cursor: 'pointer',
    width: 24,
    display: ['block', 'none']
  }
}

export default styles
