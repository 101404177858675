import React, { createElement, useRef, useContext } from 'react'
// import ReactHtmlParser from 'react-html-parser'
import { Image, Link, Text, Heading, Button } from 'rebass'
import { UneeqContext } from 'uneeq-react-core'
import { debounce } from 'lodash'
// @ts-ignore
import marksy from 'marksy'
import styles from './styles'

const headingStyles = {
  fontSize: 3,
  fontWeight: 'bold',
  m: 0,
  p: 0,
  color: 'primary'
}

export const getUtteranceFromURI = (uri: string) => {
  const match = uri.match(/^say\:(.*)$/)
  if (!match) {
    return undefined
  }
  return match[1]
}

const compile = (
  markdown: string,
  send: (text: string) => void,
  sendCallback: (text: string) => void,
  invertInformationColors: boolean
) => {
  const heading = ({ children }: any) => (
    <Heading
      sx={{
        ...headingStyles,
        color: invertInformationColors ? 'white' : 'primary'
      }}
    >
      {children}
    </Heading>
  )

  const parser = marksy({
    elements: {
      a: (props: any) => {
        const utterance = getUtteranceFromURI(props.href)
        return (
          <Link
            {...props}
            target="_blank"
            onClick={(event: Event) => {
              if (!utterance) {
                return
              }

              event.preventDefault()
              send(utterance)
              sendCallback(utterance)
            }}
          />
        )
      },
      h1: heading,
      h2: heading,
      h3: heading,
      h4: heading
    },
    createElement
  })

  return parser(markdown, {})
}

interface HeadingInformation {
  type: 'heading'
  text: string
}
interface TextInformation {
  type: 'text'
  text: string
}
interface HTMLInformation {
  type: 'html'
  html: string
}
interface MarkdownInformation {
  type: 'markdown'
  markdown: string
}
interface ImageInformation {
  type: 'image'
  source: string
  label: string
  width: string
}
interface VideoInformation {
  type: 'video'
  source: string
  width: string
  height: string
}
export interface LinkInformation {
  type: 'link'
  href: string
  label: string
}

interface IFrameInformation {
  type: 'iframe'
  source: string
  width: string
  height: string
}

interface ButtonInformation {
  type: 'button'
  label: string
  href: string
  target: string
}

export type InformationItemWithoutList =
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | IFrameInformation
  | LinkInformation
  | MarkdownInformation
  | ButtonInformation

interface ListInformation {
  type: 'list'
  items: InformationItemWithoutList[]
}

type InformationItem =
  | ListInformation
  | HeadingInformation
  | TextInformation
  | HTMLInformation
  | ImageInformation
  | VideoInformation
  | IFrameInformation
  | LinkInformation
  | MarkdownInformation
  | ButtonInformation

const renderInformationItem = (
  debouncedSend: (text: string) => any,
  dispatchSuggestedResponseSent: (text: string) => void,
  item: InformationItem,
  index: number,
  expanded: boolean = false,
  invertInformationColors: boolean = false
): any => {
  switch (item.type) {
    case 'html':
      console.warn('HTML type used')
      return null //ReactHtmlParser(item.html)
    case 'text':
      return <Text key={index}>{item.text}</Text>
    case 'heading':
      return (
        <Heading sx={headingStyles} key={index}>
          {item.text}
        </Heading>
      )
    case 'markdown':
      const compiled = compile(
        item.markdown,
        debouncedSend,
        dispatchSuggestedResponseSent,
        invertInformationColors
      )
      return compiled.tree
    case 'list':
      return (
        <ul key={index}>
          {item.items.map((child, index) =>
            renderInformationItem(
              debouncedSend,
              dispatchSuggestedResponseSent,
              child,
              index
            )
          )}
        </ul>
      )
    case 'link':
      return (
        <Link href={item.href} rel="external" target="_blank" key={index}>
          <Text>{item.label}</Text>
        </Link>
      )
    case 'button':
      const utterance = getUtteranceFromURI(item.href)
      return (
        <Link
          sx={{ textDecorationLine: 'none' }}
          href={item.href}
          target={item.target}
          key={index}
          onClick={(event: React.MouseEvent) => {
            if (!utterance) {
              return
            }

            event.preventDefault()
            debouncedSend(utterance)
            dispatchSuggestedResponseSent(utterance)
          }}
        >
          <Button
            sx={styles.information.contentButton}
            variant={
              invertInformationColors ? 'primaryInverted' : 'primaryLight'
            }
            key={index}
          >
            <Text
              color={invertInformationColors ? 'primary' : undefined}
              fontWeight="bold"
            >
              {item.label}
            </Text>
          </Button>
        </Link>
      )
    case 'image':
      return (
        <Image
          src={item.source}
          width={item.width}
          alt={item.label}
          key={index}
        />
      )
    case 'video':
    case 'iframe':
      return (
        <iframe
          title="information-content-iframe"
          src={item.source}
          width={item.width || '100%'}
          height={expanded ? '100%' : '375'}
          key={index}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )
  }
}

interface InformationProps {
  information: InformationItem[]
  expanded?: boolean
  invertInformationColors?: boolean
}

const InformationContent: React.FC<InformationProps> = ({
  information,
  expanded,
  invertInformationColors
}) => {
  const { sendText, dispatch } = useContext(UneeqContext)
  const debouncedSend = useRef(debounce((text: string) => sendText(text), 2000))
    .current
  const dispatchSuggestedResponseSent = (text: string) => {
    dispatch({ type: 'suggestedResponseSent', payload: text })
  }

  if (!information?.length) return null

  return (
    <>
      {information.map((item: InformationItem, index: number) => {
        return renderInformationItem(
          debouncedSend,
          dispatchSuggestedResponseSent,
          item,
          index,
          expanded,
          invertInformationColors
        )
      })}
    </>
  )
}

export default InformationContent
