import { useMediaDevices, usePermission } from 'react-use'
import { isSafari, isIOS } from 'react-device-detect'

export const useMicPermissionsGranted = () => {
  // Note that this is potentially dangerous if hooks don't render in the same order. HOWEVER, this is deliberate here
  // because browsers shouldn't change in type within the same session... e.g. Safari suddenly turns into Chrome
  if (isSafari || isIOS) {
    // We can check if they've accepted microphone permissions by enumerating the media devices and checking if the deviceId is present.
    // This does not seem to update instantly with Chrome until the web page is restarted, whereas it does for safari. Hence this is used
    // only for safari
    const mediaDevices: any = useMediaDevices()
    const audioInput: MediaDeviceInfo | undefined = mediaDevices?.devices?.find(
      (device: MediaDeviceInfo) => device.kind === 'audioinput'
    )

    return !!audioInput?.deviceId
  }
  // For other browsers, we can use the permissions API to check this
  const permissions = usePermission({ name: 'microphone' })
  return permissions === 'granted'
}
