import React from 'react'
import { useIsSmallScreen } from 'uneeq-react-core'
import styles from './styles'
import { Drawer, DrawerProps } from '@mui/material'

const SideDrawer: React.FC<DrawerProps & { onBackDropClicked: Function }> = ({
  children,
  onBackDropClicked,
  ...props
}) => {
  const isSmallScreen = useIsSmallScreen()
  const smallStyles = isSmallScreen ? styles.smallPaper : {}

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        ModalProps={{
          keepMounted: true
        }}
        BackdropProps={{
          invisible: !isSmallScreen
        }}
        PaperProps={{ sx: { ...styles.paper, ...smallStyles } }}
        {...props}
        onClose={() => onBackDropClicked()}
      >
        {children}
      </Drawer>
    </>
  )
}
export default SideDrawer
