export const styles = {
  navigation: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'semibold',
    color: 'primary'
  },
  safetyInformation: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'bold',
    color: 'primary'
  },
  subtext: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'semibold',
    color: 'text'
  },
  h1: {
    fontSize: '58px',
    lineHeight: '58px',
    fontWeight: 'medium',
    color: 'primary'
  },
  subhead: {
    fontSize: '32px',
    lineHeight: '34px',
    fontWeight: 'medium',
    color: 'text'
  },
  chatText: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'medium',
    color: 'tertiary'
  },
  chatDisclaimer: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'medium',
    color: 'text'
  },
  disclaimerLink: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'bold',
    color: 'primary',
    textDecoration: 'none'
  },
  h2: {
    fontSize: '25px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: 'primary'
  },
  h3: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: 'primary'
  },
  body: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'medium',
    color: 'text'
  },
  footer: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'medium',
    color: 'primaryText'
  },
  buttonText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'bold'
  }
}
export default styles
