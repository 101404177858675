import React, { useContext, useState } from 'react'
import { UneeqContext } from 'uneeq-react-core'

import { useTheme } from 'emotion-theming'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Box, Text, Flex, Link } from 'rebass'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type AccordionContentProps = {
  headerContent: React.ReactNode
}

const AccordionContent: React.FC<AccordionContentProps> = ({
  children,
  headerContent
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const theme: any = useTheme()

  const onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void = (_, expanded) => {
    setIsExpanded(expanded)
  }
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{ border: `1px solid lightgrey` }}
      expanded={isExpanded}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: theme.colors.primary }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Text color="primary" fontWeight="semibold">
          {headerContent}
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Text fontWeight="medium">{children}</Text>
      </AccordionDetails>
    </Accordion>
  )
}
const HelpDrawerContext: React.FC = () => {
  const {
    config: { avatarName },
    dispatch
  } = useContext(UneeqContext)

  const openTranscript = () => {
    dispatch({ type: 'openTranscript', payload: true })
  }

  const openFeedback = () => {
    dispatch({ type: 'openFeedback', payload: true })
  }

  return (
    <Box color="primary">
      <Text
        color="primary"
        fontSize={3}
        fontWeight="bold"
        sx={{ textTransform: 'uppercase', my: 4 }}
        textAlign="center"
      >
        Help using
        <Box as="span" color="primary">
          {` ${avatarName}`}
        </Box>
      </Text>

      <Flex flexDirection="column" sx={{ gap: 3, overflowY: 'auto' }}>
        <AccordionContent
          headerContent={'How do I start a chat using my computer?'}
        >
          To talk with
          <Box as="span" color="primary">
            {` ${avatarName} `}
          </Box>
          using your computer, hold down the spacebar and talk. When you are
          done talking, let go of the spacebar
        </AccordionContent>

        <AccordionContent
          headerContent={'How do I start a chat using my phone or tablet?'}
        >
          To talk with
          <Box as="span" color="primary">
            {` ${avatarName} `}
          </Box>
          from your phone or tablet, just talk into the microphone like you
          normally would. You'll need to first give the site access to your
          microphone and camera.
        </AccordionContent>

        <AccordionContent
          headerContent={'How can I find what we talked about today'}
        >
          You can read and download a transcript of your chat in the main menu:
          <Link
            onClick={openTranscript}
            sx={{ ':hover': { cursor: 'pointer' } }}
          >
            {' '}
            Show Chat.{' '}
          </Link>
          You'll even be able to access any links or resources discussed. Once
          you leave this chat though, you won't be able to get a copy of our
          chat anymore.
        </AccordionContent>

        <AccordionContent
          headerContent={
            <Box>
              Why does
              <Box as="span" color="primary">
                {` ${avatarName} `}
              </Box>
              ask for my name?
            </Box>
          }
        >
          <Box as="span" color="primary">
            {` ${avatarName} `}
          </Box>
          asks for your name so that she can connect with you, just the way you
          would talk with another person in real life.
        </AccordionContent>

        <AccordionContent
          headerContent={
            <Box>
              Is my chat with
              <Box as="span" color="primary">
                {` ${avatarName} `}
              </Box>
              stored in any way?
            </Box>
          }
        >
          Your privacy is very important to us. If you agreed, cookies will
          stored on your device. You can manage those{' '}
          <Link
            target="_"
            href="https://www.sanofi.com/en/data-privacy#:~:text=The%20use%20of%20cookies%20when%20you%20visit%20our%20website&text=When%20browsing%20our%20website%2C%20you,by%20modifying%20your%20browser%20settings"
          >
            cookies settings
          </Link>{' '}
          at any time.
        </AccordionContent>

        <AccordionContent headerContent="What are cookies?">
          Cookies are small pieces of text sent to your browser by this website.
          Cookies help us remember how you used this site so that we can make it
          easier for you to use it the next time you visit.
        </AccordionContent>

        <AccordionContent
          headerContent={
            <Box>
              What do I do if
              <Box as="span" color="primary">
                {` ${avatarName} `}
              </Box>
              can't answer my question or I don't get the information I need?
            </Box>
          }
        >
          Just like any person,
          <Box as="span" color="primary">
            {` ${avatarName} `}
          </Box>
          may not have all the answers. For more information about AFib and
          MULTAQ, visit{' '}
          <Link target="_" href="https://www.multaq.com">
            multaq.com.
          </Link>{' '}
          Your doctor or pharmacist are always good sources of information about
          your condition and treatment.
        </AccordionContent>

        <AccordionContent
          headerContent={
            <Box>
              <Box as="span" color="primary">
                {`${avatarName} `}
              </Box>
              didn't work exactly like I thought she would. Where can I send
              feedback?
            </Box>
          }
        >
          Thank you for wanting to make this a better experience for people with
          AFib. Please rate your experience and leave your feedback{' '}
          <Link onClick={openFeedback} sx={{ ':hover': { cursor: 'pointer' } }}>
            here.
          </Link>
        </AccordionContent>
      </Flex>
    </Box>
  )
}
export default HelpDrawerContext
