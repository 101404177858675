import React, { useState } from 'react'
import { useTheme } from 'emotion-theming'
import { MenuItem, Select, SelectProps, styled } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const StyledSelect = styled(Select)(() => {
  const theme = useTheme() as any
  return {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.semibold,
    background: theme.colors.primaryText,
    color: theme.colors.primary,
    borderRadius: 0,
    '.MuiOutlinedInput-notchedOutline': {
      paddingTop: '12px',
      paddingBottom: '12px'
    },
    '& #option-select': {
      paddingLeft: '16px'
    }
  }
})

const StyledMenuItem = styled(MenuItem)(() => {
  const theme = useTheme() as any
  return {
    fontSize: '14px',
    fontWeight: theme.fontWeights.semibold,
    color: theme.colors.primary,
    background: theme.colors.primaryText,
    borderRadius: 0,
    whiteSpace: 'break-spaces',
    minHeight: '44px !important',
    maxWidth: '564px',
    ':hover, &.Mui-selected, &.Mui-selected:hover': {
      background: theme.colors.primary,
      color: theme.colors.primaryText
    }
  }
})

type Option = {
  key: string
  value: string
}
interface DropdownProps extends SelectProps {
  options: Option[]
  width: string
  error: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
}

const Dropdown: React.FC<DropdownProps> = ({ children, ...props }) => {
  const { options, width, error, sx, setError, ...restProps } = props
  const theme = useTheme() as any
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setError(false)
    setOpen(true)
  }

  return (
    <StyledSelect
      MenuProps={{
        MenuListProps: {
          disablePadding: true
        },
        PaperProps: { style: { borderRadius: 0 } },
        disableAutoFocus: true,
        disableAutoFocusItem: true,
        disableEnforceFocus: true,
        autoFocus: false
      }}
      IconComponent={open ? ExpandLessIcon : ExpandMoreIcon}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      sx={{
        ...sx,
        maxWidth: width,
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: error ? theme.colors.error : theme.colors.primaryLight
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: error ? theme.colors.error : theme.colors.primaryLight
        },
        color: error ? theme.colors.error : theme.colors.primary
      }}
      SelectDisplayProps={{
        style: { paddingTop: '10.5px', paddingBottom: '10.5px' }
      }}
      {...restProps}
    >
      {children}
      {options.map((option) => (
        <StyledMenuItem
          key={option.key}
          value={option.key}
          sx={{ maxWidth: width }}
        >
          {option.value}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  )
}

export default Dropdown
