export const styles = {
  mainContainer: {
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none'
    },
    '& *': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    alignItems: 'center',
    label: 'homeMainContainer',
    minWidth: '100%',
    height: '100%',
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'normal',
      'normal'
    ],
    width: '100%',
    '& > video': {
      objectFit: 'cover',
      width: '100vw',
      height: [
        'calc(60vh - 48px)',
        'calc(60vh - 48px)',
        'calc(60vh - 48px)',
        'calc(60vh - 48px)',
        '100vh',
        '100vh'
      ],
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 0
    },
    maxHeight: '100%'
  },
  videoContainer: {
    my: 2,
    width: '28%',
    display: 'block'
  },
  textContainer: {
    label: 'text-container',
    flexDirection: 'column',
    gap: 6,
    mb: [4, 0],
    marginLeft: 'auto',
    padding: ['10px', '10px', '10px', '10px', '0', '0'],
    alignContent: 'center',
    mt: 0,
    justifyContent: [
      'start',
      'start',
      'start',
      'start',
      'start',
      'space-around'
    ],
    height: 'auto',
    minHeight: ['40vh', '40vh', '40vh', '40vh', 'auto', 'auto'],
    display: 'block',
    maxWidth: ['100%', '100%', '100%', '100%', '45%', '40%'],
    color: 'rgb(33,77,159)',
    textAlign: 'left',
    zIndex: 4,
    background: ['white', 'white', 'white', 'white', 'none', 'none'],
    maxHeight: '100%',
    overflowY: 'scroll',
    alignSelf: [
      'flex-end',
      'flex-end',
      'flex-end',
      'flex-end',
      'center',
      'center'
    ]
  },
  headerText: {
    fontSize: [38, 38, 55, 55, 55, 60],
    lineHeight: 1
  },
  subtitle: {
    fontSize: [2, 2, 3, 3, 3, 3],
    mt: [0, 0, 0, 10, 10, 10],
    mb: [3, 3, 3, 10, 10, 10],
    color: 'rgb(113,113,115)',
    lineHeight: 1,
    display: 'block'
  },
  sophieBGImage: {
    label: 'sophieBGImage',
    position: 'fixed',
    backgroundSize: ['100%', '100%', '70%'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: ['top', 'top', '100% bottom'],
    top: ['3em', '3em', 'unset'],
    right: ['3em', '3em', 'unset'], // this is bc the empty space the img has, we should ask them for a better png
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  letsChatButton: {
    lineHeight: '24px',
    fontWeight: '600',
    minWidth: '10rem',
    py: [3, 2],
    px: [6, 5],
    mt: 3,
    mb: 10
  },
  privacyTextToggle: {
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: 0,
    lineHeight: '22px',
    textDecoration: 'none'
  },
  disclaimer: {
    mt: 9,
    mb: 6,
    fontWeight: '300',
    fontSize: 0,
    lineHeight: '22px',
    '& a, & a:active': {
      color: 'primary',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  },
  recaptchaDisclaimer: {
    fontWeight: '300',
    fontSize: 0,
    lineHeight: '22px',
    mb: 14
  },
  startButton: {
    label: 'startButton',
    mt: 1,
    mx: 'auto',
    zIndex: 4,
    width: ['300px', '300px', '210px', '210px', '210px', '210px'],
    border: 'none',
    fontSize: ['15px', '15px', '14px', '14px', '14px', '14px'],
    background: 'rgb(33,77,159)',
    marginBottom: '5px',
    borderRadius: 0,
    px: 0,
    py: ['2px', '2px', '8px', '8px', '8px', '8px'],
    '&:hover': {
      background: 'rgb(52,107,192)'
    }
  },
  surveyToggle: {
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: 2,
    lineHeight: '24px',
    textDecoration: 'none',
    mb: 2,
    mt: [10, 10, 10, 10, 0, 0],
    pt: [10, 10, 10, 10, 0, 0]
  },
  togglePrivacyIcon: {
    display: 'inline-block',
    color: 'primary'
  },
  surveyContainer: {
    transition: 'max-height .5s linear',
    overflow: 'hidden',
    '& label': {
      fontWeight: '300',
      fontSize: 2,
      alignItems: 'flex-start',
      lineHeight: '24px'
    },
    '& textarea': {
      borderRadius: 'button',
      borderColor: 'grey',
      backgroundColor: 'white'
    },
    maxWidth: 440
  },
  checkbox: {
    width: '20px',
    height: '20px',
    borderRadius: 'button',
    'input:checked ~ &': {
      color: 'text',
      backgroundColor: 'white'
    }
  },
  textarea: {
    color: 'text',
    fontFamily: 'sans-serif',
    fontSize: 1
  },
  paragraphContainer: {
    transition: 'max-height .5s linear',
    overflow: 'hidden',
    fontSize: '12px',
    lineHeight: '18px',
    flexDirection: 'column',
    fontWeight: '300',
    px: 0,
    mt: 2,
    '& p': {
      mb: 3
    }
    // maxWidth: 480
  },
  privacyPolicyLink: {
    textDecoration: 'underline',
    color: 'currentColor'
  },
  titleContainer: {
    width: '100%',
    textAlign: 'center'
  },
  seperator: {
    display: ['none', 'none', 'none', 'none', 'inherit'],
    border: 'none',
    height: '1px',
    color: 'white',
    backgroundColor: 'white',
    width: '100%'
  }
}
export default styles
