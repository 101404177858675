import { theme } from 'digital-human/src/theme'

export default {
  container: {
    label: 'transcript-container',
    zIndex: 5,
    bottom: 0,
    right: [0, 0, 0, 0, 10, 10],
    height: ['100%', '100%', '100%', '100%', '85%', '85%'],
    width: ['100%', '100%', '100%', '100%', 0, 0],
    backgroundColor: [
      theme.colors.darkBackground,
      theme.colors.darkBackground,
      theme.colors.darkBackground,
      theme.colors.darkBackground,
      'transparent',
      'transparent'
    ],
    position: 'absolute'
  },
  transcript: {
    label: 'Transcript',
    position: 'absolute',
    flexDirection: 'column'
  },
  transcriptBox: {
    bottom: ['2.5%', '2.5%', '2.5%', '2.5%', 0, 0],
    right: ['2.5%', '2.5%', '2.5%', '2.5%', 10, 10],
    height: ['95%', '95%', '95%', '95%', '85%', '85%'],
    width: ['95%', '95%', '95%', '95%', 0, 0],
    position: 'absolute'
  },
  transcriptImage: {
    width: 23,
    height: 23
  },

  header: {
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    zIndex: 3,
    cursor: ['auto', 'auto', 'auto', 'auto', 'move', 'move']
  },

  headerBigScreen: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex']
  },
  headerSmallScreen: {
    display: ['flex', 'flex', 'flex', 'flex', 'none', 'none']
  },

  headerText: {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 16
  },

  downloadIcon: {
    width: '24px',
    height: '24px',
    mt: '10px',
    ml: '20px'
  },
  download: {
    cursor: 'pointer'
  },
  tooltip: {
    backgroundColor: 'text',
    color: 'white',
    borderRadius: '4px',
    p: '4px 8px',
    fontSize: 2,
    display: 'none',
    zIndex: 5,
    position: 'absolute'
  },
  pinButton: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover .transcript-tooltip': { display: 'block' }
    }
  },
  pinIcon: {
    ml: '6px',
    pr: '15px'
  },
  scrollArea: {
    flex: 1,
    overflow: 'auto',
    justifyContent: 'flex-end'
  },
  scrollContent: {
    minHeight: '100%',
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
    mt: 'auto',
    '& > :first-of-type': {
      mt: 'auto !important'
    },
    '& .avatar-message + .avatar-message, & .user-message + .user-message': {
      mt: 1
    }
  },
  message: {
    mt: 3,
    padding: 6,
    width: '100%',
    border: '1px solid #ebeded',
    ml: 'auto',
    mr: 0,
    fontSize: 16,
    lineHeight: '19px'
  },
  link: { color: '#333' },
  footer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '8px solid',
    borderColor: 'primary'
  },
  questionInputLabel: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden'
  },
  textInput: {
    fontSize: [1, 0],
    border: 'none',
    borderRadius: [30, 0],
    width: '86%',
    height: 48,
    ml: [3, 0],
    '&::placeholder': { opacity: '0.6', textTransform: 'uppercase' },
    zIndex: 1,
    '&:focus': {
      outline: 'none'
    },
    background: ['white', 'transparent'],
    fontFamily: 'body'
  },
  sendButton: {
    cursor: 'pointer',
    width: 37,
    height: 37,
    mr: [3, 0],
    color: 'primary'
  }
}
