import atlantisLogo from './assets/img/atlantis-health-logo.png'

const logos = {
  logo2: atlantisLogo
}

export const downloadPdf = async (
  type: 'transcript' | 'savedItems',
  items: any,
  sessionId: string,
  avatarName: string,
  username?: string
) => {
  import('uneeq-download-pdf').then(
    ({ downloadSavedItemsPdf, downloadTranscriptPdf }) => {
      if (type === 'transcript') {
        downloadTranscriptPdf({
          filename: 'uneeq-transcript',
          content: items,
          sessionId,
          avatarName,
          username,
          ...logos
        })
      } else {
        downloadSavedItemsPdf({
          filename: 'uneeq-information',
          content: items,
          sessionId,
          ...logos
        })
      }
    }
  )
}

export default downloadPdf
