const stripSsml = (ssmlText: string) => {
  const regex = /<\/?uneeq[^>]*>/gi

  // An xmlns must be defined to make Amazon SSML valid XML
  const withNamespace = ssmlText
    .replace(
      '<speak>',
      '<speak xmlns="http://www.w3.org/2001/10/synthesis" version="1.0" xmlns:uneeq="uneeq" xmlns:amazon="amazon">'
    )
    .replaceAll(regex, '')

  const parser = new DOMParser()
  const ssml = parser.parseFromString(withNamespace, 'text/xml')
  if (ssml.getElementsByTagName('parsererror').length) {
    return withNamespace
  }
  return ssml?.firstChild?.textContent
}

export default stripSsml
