import React from 'react'
import { Flex, Box } from 'rebass'
import styles from './styles'
import SuggestedResponses from '../OnScreenInfo/SuggestedResponses'
import { useUneeqState } from 'uneeq-react-core'

const SideBar: React.FC = () => {
  const { onScreenInfo } = useUneeqState()

  const show = onScreenInfo?.suggestedResponses?.sideLayout

  return (
    <Flex sx={styles.bar} display={show ? undefined : 'none !important'}>
      <Box sx={styles.container}>
        <SuggestedResponses sideLayout={true} />
      </Box>
    </Flex>
  )
}

export default SideBar
