import downloadPdf from './app/downloadPdf'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

ReactGA.initialize('UA-90107041-4')
TagManager.initialize({ gtmId: 'GTM-MGS9GTT' })

let environment = {}

const config = {
  debug: false,

  url: process.env.REACT_APP_UNEEQ_URL!,
  conversationId: process.env.REACT_APP_UNEEQ_PERSONA_ID!,
  tokenUrl: process.env.REACT_APP_UNEEQ_TOKEN_URL!,

  // Is a passcode needed to obtain a token? Show a passcode screen so the user can provide it.
  usePasscode: false,
  // Recaptcha - if used with passcode
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY!,

  // welcome can be suppressed during dev with REACT_APP_NO_WELCOME=1
  playWelcome: false,
  sendLocalVideo: false,
  sendLocalAudio: false,
  customData: {},

  // When holding down spacebar, durations shorter that this will trigger
  // a message like "Keep holding spacebar while you talk"
  tapThreshold: 700, // ms

  // How long can the user be inactive before timeout
  timeout: 210 * 1000, // ms
  // How close to the end of the timeout should we show the warning
  timeoutWarning: 60 * 1000, // ms
  // e.g. timeout=90sec, timeoutWarning=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends

  // If the avatar is not centred within the video avatarPosition needs to be changed.
  // This will ensure correct positioning narrow (mobile) screens
  avatarAspect: 16 / 9, // video aspect ratio
  avatarPosition: 0.5, // Where is the avatar in the video frame (0.5 = center, 0.33 = 1/3 from the left)

  informationInTranscript: false,

  // show we show the contact form after Feedback?
  showEscalationForm: false,

  // How many empty transcripts before an error is shown
  emptyTranscriptThreshold: 3,
  avatarName: 'Adele',

  downloadPdf,

  // Optional - track events
  analytics: (label: string, action?: string) => {
    ReactGA.event({
      category: 'Digital Coach',
      label,
      action: action ?? 'Default Action'
    })
  },

  ...environment
}

export default config
