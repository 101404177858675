import React, { useEffect, useState } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import Information from './Information'
import { Box } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'
import styles from './styles'
import _ from 'lodash'

const MotionCard = motion.custom(Box)

const OnScreenInfo = () => {
  const {
    onScreenInfo,
    avatarSpeaking,
    informationShowDelay,
    informationHideDelay
  } = useUneeqState()
  const [show, setShow] = useState(false)
  const [information, setInformation] = useState()
  const [showTimeout, setShowTimeout] = useState<NodeJS.Timeout>()
  const [hideTimeout, setHideTimeout] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (!_.isEqual(onScreenInfo.information, information)) {
      // Reset only if the information content has changed (but not when she starts speaking)
      setShow(false)
      showTimeout && clearTimeout(showTimeout)
      hideTimeout && clearTimeout(hideTimeout)
      setInformation(onScreenInfo.information)
    }

    if (!avatarSpeaking) {
      return
    }

    setShowTimeout(setTimeout(() => setShow(true), informationShowDelay))

    if (informationHideDelay) {
      setHideTimeout(setTimeout(() => setShow(false), informationHideDelay))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onScreenInfo.information,
    informationShowDelay,
    informationHideDelay,
    avatarSpeaking
  ])

  return (
    <>
      <AnimatePresence>
        {onScreenInfo.information && show && (
          <Box sx={styles.container} id="onscreeninfo-container">
            <MotionCard
              sx={{
                ...styles.card,
                backgroundColor: onScreenInfo.invertInformationColors
                  ? 'primaryLight'
                  : undefined,
                textAlign: onScreenInfo.textAlign,
                p: onScreenInfo.informationPadding
                  ? `${onScreenInfo.informationPadding} !important`
                  : undefined
              }}
              variant="card"
              initial={{
                opacity: 0,
                transform: 'translate(0px, 200px)'
              }}
              animate={{
                opacity: 1,
                transform: `translate(0px, 0px)`
              }}
              exit={{
                opacity: 0,
                transform: `translate(0px, -200px)`
              }}
            >
              <Information
                information={onScreenInfo.information}
                invertInformationColors={onScreenInfo.invertInformationColors}
              />
            </MotionCard>
          </Box>
        )}
      </AnimatePresence>
    </>
  )
}

export default OnScreenInfo
